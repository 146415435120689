<template>
    <div id="progress-bar--mobile" class="progress-bar progress-bar--mobile">
        <div class="wrapper">
            <ul v-bind:data-step="stepCurrent">
                <li v-for="step in steps"
                    class="step"
                    :key="step.index"
                    :data="step.index"
                    :disabled="stepPopulated >= step.index"
                    :class="{
                        complete: stepCurrent > step.index,
                        active: stepCurrent == step.index,
                        populated: stepPopulated >= step.index,
                    }"
                >
                    <router-link
                        :event="stepPopulated >= step.index ? 'click' : ''"
                        :to="step.location"
                    >
                        <span class="icon">
                            <Icon :icon="step.icon"/>
                        </span>
                        <span class="title">
                            {{step.title}}
                        </span>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MobileProgressBar',
    props: {
        stepCurrent: {
            type: Number,
            required: true
        },
        stepPopulated: {
            type: Number,
            required: true
        },
        steps: {
            type: Array,
            required: true
        },
    },
};
</script>

<style scoped>
.progress-bar {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #4b4b4b;
    color: #fff;
    position: relative;
    z-index: 1;
}

.progress-bar ul {
    position: relative;
    z-index: 0;
    list-style-type: none;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
}

.progress-bar ul:before,
.progress-bar ul:after {
    content: '\00a0';
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(50% - 1px);
    right: auto;
    width: 3px;
    background-color: #636364;
    z-index: -2;
}

.progress-bar ul:after {
    bottom: auto;
    background-color: #00b8d6;
    width: 3px;
}

/* gradient positioning for progress bar completetion indicator */
.progress-bar ul[data-step='1']:after {
    height: 25px;
    background-color: #e23f88;
}

.progress-bar ul[data-step='2']:after {
    height: calc(20% + 20px);
    background: rgb(0, 184, 214);
    background: -moz-linear-gradient(
        180deg,
        rgba(0, 184, 214, 1) 0%,
        rgba(226, 63, 136, 1) 95%
    );
    background: -webkit-linear-gradient(
        180deg,
        rgba(0, 184, 214, 1) 0%,
        rgba(226, 63, 136, 1) 95%
    );
    background: linear-gradient(
        180deg,
        rgba(0, 184, 214, 1) 0%,
        rgba(226, 63, 136, 1) 95%
    );
}

.progress-bar ul[data-step='3']:after {
    height: calc(40% + 15px);
    background: rgb(0, 184, 214);
    background: -moz-linear-gradient(
        180deg,
        rgba(0, 184, 214, 1) 50%,
        rgba(226, 63, 136, 1) 95%
    );
    background: -webkit-linear-gradient(
        180deg,
        rgba(0, 184, 214, 1) 50%,
        rgba(226, 63, 136, 1) 95%
    );
    background: linear-gradient(
        180deg,
        rgba(0, 184, 214, 1) 50%,
        rgba(226, 63, 136, 1) 95%
    );
}

.progress-bar ul[data-step='4']:after {
    height: calc(60% + 10px);
    background: rgb(0, 184, 214);
    background: -moz-linear-gradient(
        180deg,
        rgba(0, 184, 214, 1) 66.66%,
        rgba(226, 63, 136, 1) 95%
    );
    background: -webkit-linear-gradient(
        180deg,
        rgba(0, 184, 214, 1) 66.66%,
        rgba(226, 63, 136, 1) 95%
    );
    background: linear-gradient(
        180deg,
        rgba(0, 184, 214, 1) 66.66%,
        rgba(226, 63, 136, 1) 95%
    );
}

.progress-bar ul[data-step='5']:after {
    height: calc(80% + 5px);
    background: rgb(0, 184, 214);
    background: -moz-linear-gradient(
        180deg,
        rgba(0, 184, 214, 1) 75%,
        rgba(226, 63, 136, 1) 95%
    );
    background: -webkit-linear-gradient(
        180deg,
        rgba(0, 184, 214, 1) 75%,
        rgba(226, 63, 136, 1) 95%
    );
    background: linear-gradient(
        180deg,
        rgba(0, 184, 214, 1) 75%,
        rgba(226, 63, 136, 1) 95%
    );
}

.progress-bar ul[data-step='6']:after {
    height: 100%;
    background: rgb(0, 184, 214);
    background: -moz-linear-gradient(
        180deg,
        rgba(0, 184, 214, 1) 80%,
        rgba(226, 63, 136, 1) 95%
    );
    background: -webkit-linear-gradient(
        180deg,
        rgba(0, 184, 214, 1) 80%,
        rgba(226, 63, 136, 1) 95%
    );
    background: linear-gradient(
        180deg,
        rgba(0, 184, 214, 1) 80%,
        rgba(226, 63, 136, 1) 95%
    );
}

.progress-bar .step {
    box-sizing: border-box;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex: 0 1 auto;
    -webkit-box-flex: 0;
    flex: 0 1 auto;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.progress-bar .step:nth-child(even) {
    text-align: left;
    clear: right;
    padding-left: calc(50% + 10px);
}

.progress-bar .step:nth-child(odd) {
    text-align: right;
    clear: left;
    padding-right: calc(50% + 10px);
}

.progress-bar a {
    box-sizing: border-box;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex: 0 1 auto;
    -webkit-box-flex: 0;
    flex: 0 1 auto;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;

    text-decoration: none;
    color: inherit;
    width: 100%;
    cursor: default;
}

.progress-bar .active a,
.progress-bar .complete a,
.progress-bar .populated a {
    cursor: pointer;
}

.progress-bar .step:nth-child(odd) a {
    -ms-flex-direction: row-reverse;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    flex-direction: row-reverse;
}

.progress-bar .icon {
    display: block;
    position: relative;
    background-color: #636364;
    width: 25px;
    height: 25px;
}

.progress-bar .step:nth-child(even) .icon {
    float: left;
}

.progress-bar .step:nth-child(odd) .icon {
    float: right;
}

.progress-bar .icon:after {
    content: '\00a0';
    width: 0;
    height: 0;
    position: absolute;
    top: calc(50% - 5px);
}

.progress-bar .step:nth-child(even) .icon:after {
    border-left: 5px solid #636364;
    border-right: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
    left: 100%;
}

.progress-bar .step:nth-child(odd) .icon:after {
    border-left: 5px solid transparent;
    border-right: 5px solid #636364;
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
    right: 100%;
}

.progress-bar .populated .icon {
    background-color: #008988;
}

.progress-bar .populated:nth-child(even) .icon:after {
    border-left: 5px solid #008988;
}

.progress-bar .populated:nth-child(odd) .icon:after {
    border-right: 5px solid #008988;
}

.progress-bar .active .icon {
    background-color: #e23f88;
}

.progress-bar .active:nth-child(even) .icon:after {
    border-left: 5px solid #e23f88;
}

.progress-bar .active:nth-child(odd) .icon:after {
    border-right: 5px solid #e23f88;
}

.progress-bar .complete .icon {
    background-color: #00b8d6;
}

.progress-bar .complete:nth-child(even) .icon:after {
    border-left: 5px solid #00b8d6;
}

.progress-bar .complete:nth-child(odd) .icon:after {
    border-right: 5px solid #00b8d6;
}

.progress-bar svg {
    width: 25px;
    height: 25px;
    opacity: 0.3;
}

.progress-bar .complete svg,
.progress-bar .active svg,
.progress-bar .populated svg {
    opacity: 1;
}

.progress-bar .title {
    margin-top: 5px;
    display: block;
    opacity: 0.3;
    font-size: 1.3rem;
    white-space: nowrap;
}

.progress-bar .complete .title,
.progress-bar .active .title {
    opacity: 1;
}

.progress-bar .step:nth-child(even) .title {
    padding-left: 10px;
}

.progress-bar .step:nth-child(odd) .title {
    padding-right: 10px;
}
</style>

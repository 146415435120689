<template>
    <div id="progress-bar" class="progress-bar"
        :class="{
            sticky: progressBarSticky == true
            }"
        >
        <div class="progress-bar__bar">
            <div class="wrapper">
                <ul v-bind:data-step="stepCurrent">
                    <li v-for="step in steps"
                        class="step"
                        :key="step.index"
                        :data="step.index"
                        :disabled="stepPopulated >= step.index"
                        :class="{
                            complete: stepCurrent > step.index,
                            active: stepCurrent == step.index,
                            populated: stepPopulated >= step.index,
                        }"
                    >
                        <router-link
                            :event="stepPopulated >= step.index ? 'click' : ''"
                            :to="step.location"
                        >
                            <span class="icon">
                                <Icon :icon="step.icon"/>
                            </span>
                            <span class="title">
                                {{step.title}}
                            </span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProgressComp',
    props: {
        stepCurrent: {
            type: Number,
            required: true
        },
        stepPopulated: {
            type: Number,
            required: true
        },
        steps: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            progressBarSticky: null,
        };
    },
    methods: {
        onScroll() {
            var wScrollY = window.scrollY
            var dHeight = document.body.scrollHeight
            var wHeight = window.innerHeight;
            var fHeight;
            var pbHeight;
            var pbTopOffset = document.getElementById("progress-bar").offsetTop

            if (window.innerWidth < 560) {
                fHeight = 212 + 212 + 65;
                pbHeight = 65;
            } else if (window.innerWidth >= 560 && window.innerWidth < 768) {
                fHeight = 166 + 101;
                pbHeight = 101;
            } else if (window.innerWidth >= 768) {
                fHeight = 184;
                pbHeight = 101;
            }

            // if the document height is larger than the window height
            if (wScrollY == 0 || fHeight > 0 && dHeight > (wHeight + fHeight)) {
                // initialise sticky progress bar
                this.progressBarSticky = true
                 document.documentElement.classList.add('progress-sticky');
                if(wScrollY > 0 && pbTopOffset <= (wScrollY + (wHeight - pbHeight))) {
                    // disable the sticky progress bar
                    this.progressBarSticky = false;
                    document.documentElement.classList.remove('progress-sticky');
                } else {
                    // enable the sticky progress bar
                    this.progressBarSticky = true
                    document.documentElement.classList.add('progress-sticky');
                }
            } else {
                // disable the sticky progress bar
                this.progressBarSticky = false
                document.documentElement.classList.remove('progress-sticky');
            }
        },
    },
    created() {
        window.addEventListener('scroll', this.onScroll);
        window.addEventListener('resize', this.onScroll);
    },
    mounted() {
        this.onScroll();
    },
    destroyed() {
        window.removeEventListener('scroll', this.onScroll);
        window.removeEventListener('resize', this.onScroll);
    }
};
</script>

<style scoped>
.progress-bar {
    background-color: #414142;
    height: 65px;
}

.progress-bar.sticky .progress-bar__bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100vw;
}

.progress-bar__bar {
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #414142;
    color: #fff;
}

.progress-bar ul {
    position: relative;
    z-index: 0;
    list-style-type: none;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;

    box-sizing: border-box;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex: 0 1 auto;
    -webkit-box-flex: 0;
    flex: 0 1 auto;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;

    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.progress-bar ul:before,
.progress-bar ul:after {
    content: '\00a0';
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    right: 0;
    height: 3px;
    background-color: #636364;
    z-index: -2;
}

.progress-bar ul:after {
    right: auto;
    background-color: #00b8d6;
    width: 0;
}

/* gradient positioning for progress bar completetion indicator */
.progress-bar ul[data-step='1']:after {
    width: 25px;
    background-color: #e23f88;
}

.progress-bar ul[data-step='2']:after {
    width: calc(20% + 20px);
    background: rgb(0, 184, 214);
    background: -moz-linear-gradient(
        90deg,
        rgba(0, 184, 214, 1) 0%,
        rgba(226, 63, 136, 1) 95%
    );
    background: -webkit-linear-gradient(
        90deg,
        rgba(0, 184, 214, 1) 0%,
        rgba(226, 63, 136, 1) 95%
    );
    background: linear-gradient(
        90deg,
        rgba(0, 184, 214, 1) 0%,
        rgba(226, 63, 136, 1) 95%
    );
}

.progress-bar ul[data-step='3']:after {
    width: calc(40% + 15px);
    background: rgb(0, 184, 214);
    background: -moz-linear-gradient(
        90deg,
        rgba(0, 184, 214, 1) 50%,
        rgba(226, 63, 136, 1) 95%
    );
    background: -webkit-linear-gradient(
        90deg,
        rgba(0, 184, 214, 1) 50%,
        rgba(226, 63, 136, 1) 95%
    );
    background: linear-gradient(
        90deg,
        rgba(0, 184, 214, 1) 50%,
        rgba(226, 63, 136, 1) 95%
    );
}

.progress-bar ul[data-step='4']:after {
    width: calc(60% + 10px);
    background: rgb(0, 184, 214);
    background: -moz-linear-gradient(
        90deg,
        rgba(0, 184, 214, 1) 66.66%,
        rgba(226, 63, 136, 1) 95%
    );
    background: -webkit-linear-gradient(
        90deg,
        rgba(0, 184, 214, 1) 66.66%,
        rgba(226, 63, 136, 1) 95%
    );
    background: linear-gradient(
        90deg,
        rgba(0, 184, 214, 1) 66.66%,
        rgba(226, 63, 136, 1) 95%
    );
}

.progress-bar ul[data-step='5']:after {
    width: calc(80% + 5px);
    background: rgb(0, 184, 214);
    background: -moz-linear-gradient(
        90deg,
        rgba(0, 184, 214, 1) 75%,
        rgba(226, 63, 136, 1) 95%
    );
    background: -webkit-linear-gradient(
        90deg,
        rgba(0, 184, 214, 1) 75%,
        rgba(226, 63, 136, 1) 95%
    );
    background: linear-gradient(
        90deg,
        rgba(0, 184, 214, 1) 75%,
        rgba(226, 63, 136, 1) 95%
    );
}

.progress-bar ul[data-step='6']:after {
    width: 100%;
    background: rgb(0, 184, 214);
    background: -moz-linear-gradient(
        90deg,
        rgba(0, 184, 214, 1) 80%,
        rgba(226, 63, 136, 1) 95%
    );
    background: -webkit-linear-gradient(
        90deg,
        rgba(0, 184, 214, 1) 80%,
        rgba(226, 63, 136, 1) 95%
    );
    background: linear-gradient(
        90deg,
        rgba(0, 184, 214, 1) 80%,
        rgba(226, 63, 136, 1) 95%
    );
}

.progress-bar .step {
    text-align: center;

    position: static;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;

    width: 25px;
}

.progress-bar a {
    text-decoration: none;
    color: inherit;
    display: block;
    cursor: default;
}

.progress-bar .active a,
.progress-bar .complete a,
.progress-bar .populated a {
    cursor: pointer;
}

.progress-bar .icon {
    display: block;
    position: relative;
    background-color: #636364;
    width: 25px;
    height: 25px;
    margin-right: auto;
    margin-left: auto;
}

.progress-bar .icon:after {
    content: '\00a0';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-top: 5px solid #636364;
    position: absolute;
    top: 100%;
    left: calc(50% - 5px);
}

.progress-bar .populated .icon {
    background-color: #008988;
}

.progress-bar .populated .icon:after {
    border-top: 5px solid #008988;
}

.progress-bar .active .icon {
    background-color: #e23f88;
}

.progress-bar .active .icon:after {
    border-top: 5px solid #e23f88;
}

.progress-bar .complete .icon {
    background-color: #00b8d6;
}

.progress-bar .complete .icon:after {
    border-top: 5px solid #00b8d6;
}

.progress-bar svg {
    width: 25px;
    height: 25px;
    opacity: 0.3;
}

.progress-bar .complete svg,
.progress-bar .active svg,
.progress-bar .populated svg {
    opacity: 1;
}

.progress-bar .title {
    margin-top: 5px;
    display: block;
    opacity: 0.3;
    font-size: 1.3rem;
}

.progress-bar .complete .title,
.progress-bar .active .title {
    opacity: 1;
}

/* BP MAX Smaller */
@media screen and (max-width: 34.9375em) {
    /* 559px */
    .progress-bar .title {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
}

/* BP Smaller */
@media screen and (min-width: 35em) {
    /* 560px */
    .progress-bar {
        height: 101px;
    }

    .progress-bar__bar {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .progress-bar .wrapper {
        /* negative margin to align first and last step as each step has a miniumum width of 118px */
        margin-left: -20px;
        margin-right: -21px;
    }

    .progress-bar ul {
        margin-bottom: 0;
    }

    .progress-bar ul:before,
    .progress-bar ul:after {
        top: 12px;
        height: 1px;
    }

    .progress-bar ul:after {
        right: auto;
        background-color: #00b8d6;
        width: 0;
    }

    /* gradient positioning for progress bar completetion indicator */
    .progress-bar ul[data-step='1']:after {
        width: 0;
        background-color: #00b8d6;
    }

    .progress-bar ul[data-step='2']:after {
        width: calc(20% + 15px);
        background: rgb(0, 184, 214);
        background: -moz-linear-gradient(
            90deg,
            rgba(0, 184, 214, 1) 40%,
            rgba(226, 63, 136, 1) 95%
        );
        background: -webkit-linear-gradient(
            90deg,
            rgba(0, 184, 214, 1) 40%,
            rgba(226, 63, 136, 1) 95%
        );
        background: linear-gradient(
            90deg,
            rgba(0, 184, 214, 1) 40%,
            rgba(226, 63, 136, 1) 95%
        );
    }

    .progress-bar ul[data-step='3']:after {
        width: calc(40% + 13px);
        background: rgb(0, 184, 214);
        background: -moz-linear-gradient(
            90deg,
            rgba(0, 184, 214, 1) 55%,
            rgba(226, 63, 136, 1) 95%
        );
        background: -webkit-linear-gradient(
            90deg,
            rgba(0, 184, 214, 1) 55%,
            rgba(226, 63, 136, 1) 95%
        );
        background: linear-gradient(
            90deg,
            rgba(0, 184, 214, 1) 55%,
            rgba(226, 63, 136, 1) 95%
        );
    }

    .progress-bar ul[data-step='4']:after {
        width: calc(60% - 13px);
        background: rgb(0, 184, 214);
        background: -moz-linear-gradient(
            90deg,
            rgba(0, 184, 214, 1) 75%,
            rgba(226, 63, 136, 1) 95%
        );
        background: -webkit-linear-gradient(
            90deg,
            rgba(0, 184, 214, 1) 75%,
            rgba(226, 63, 136, 1) 95%
        );
        background: linear-gradient(
            90deg,
            rgba(0, 184, 214, 1) 75%,
            rgba(226, 63, 136, 1) 95%
        );
    }

    .progress-bar ul[data-step='5']:after {
        width: calc(80% - 13px);
        background: rgb(0, 184, 214);
        background: -moz-linear-gradient(
            90deg,
            rgba(0, 184, 214, 1) 77%,
            rgba(226, 63, 136, 1) 95%
        );
        background: -webkit-linear-gradient(
            90deg,
            rgba(0, 184, 214, 1) 77%,
            rgba(226, 63, 136, 1) 95%
        );
        background: linear-gradient(
            90deg,
            rgba(0, 184, 214, 1) 77%,
            rgba(226, 63, 136, 1) 95%
        );
    }

    .progress-bar ul[data-step='6']:after {
        width: calc(100% - 25px);
        background: rgb(0, 184, 214);
        background: -moz-linear-gradient(
            90deg,
            rgba(0, 184, 214, 1) 80%,
            rgba(226, 63, 136, 1) 95%
        );
        background: -webkit-linear-gradient(
            90deg,
            rgba(0, 184, 214, 1) 80%,
            rgba(226, 63, 136, 1) 95%
        );
        background: linear-gradient(
            90deg,
            rgba(0, 184, 214, 1) 80%,
            rgba(226, 63, 136, 1) 95%
        );
    }

    .progress-bar .step {
        min-width: 88px;
        width: auto;
    }

    .progress-bar .step:first-child .icon:before,
    .progress-bar .step:last-child .icon:before {
        content: '\00a0';
        position: absolute;
        top: calc(50% - 2px);
        right: calc(100% + 2px);
        height: 4px;
        background-color: #414142;
        width: 50vw;
        z-index: 0;
    }

    .progress-bar .step:last-child .icon:before {
        right: auto;
        left: calc(100% + 2px);
    }

    .progress-bar .step .icon:after {
        content: '\00a0';
        position: absolute;
        width: 39px;
        height: 39px;
        top: -7px;
        bottom: -7px;
        left: -7px;
        right: -7px;
        border: 7px solid #414142;
        z-index: -1;
    }

    .progress-bar .title {
        font-size: 1.2rem;
    }
}

@media only screen and (min-width: 48em) {
    /* 768px */
    .progress-bar__bar {
        overflow: hidden;
    }

    .progress-bar .wrapper {
        /* negative margin to align first and last step as each step has a miniumum width of 118px */
        margin-left: -23px;
        margin-right: -24px;
    }

    .progress-bar .step {
        min-width: 96px;
    }

    .progress-bar .title {
        font-size: 1.3rem;
    }
}

@media only screen and (min-width: 80em) {
    /* 1280px */
    .progress-bar .step {
        min-width: 118px;
    }

    .progress-bar .wrapper {
        /* negative margin to align first and last step as each step has a miniumum width of 118px */
        margin-left: -27px;
        margin-right: -28px;
    }

    .progress-bar__bar {
        /* line up progress bar with footer */
        padding-left: calc((100vw - 1010px) / 2);
        padding-right: calc((100vw - 1010px) / 2);
    }

    .progress-bar ul[data-step='2']:after {
        width: calc(20% + 20px);
    }

    .progress-bar ul[data-step='3']:after {
        width: 40%;
    }

    .progress-bar ul[data-step='4']:after {
        width: calc(60% - 25px);
    }

    .progress-bar ul[data-step='5']:after {
        width: calc(80% - 50px);
    }

    .progress-bar ul[data-step='6']:after {
        width: calc(100% - 75px);
    }
}
</style>

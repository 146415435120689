<template>
    <div>
        <MobileProgressBar
            v-if="isMobile"
            :stepCurrent="stepCurrent"
            :stepPopulated="stepPopulated"
            :steps="steps"
        />
        <DesktopProgressBar
            v-else
            :stepCurrent="stepCurrent"
            :stepPopulated="stepPopulated"
            :steps="steps"
        />
    </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import DesktopProgressBar from '@/components/DesktopProgressBar';
import MobileProgressBar from '@/components/MobileProgressBar';

@Component({
    components: {
        DesktopProgressBar,
        MobileProgressBar,
    },
    props: {
        stepCurrent: Number,
        stepPopulated: Number,
        steps: Array,
    }
})
export default class ProgressBar extends Vue {

    isMobile = false;

    onResize() {
        this.isMobile = window.innerWidth < 560;
    }

    created() {
        window.addEventListener('resize', this.onResize);
        this.onResize();
    }

    destroyed() {
        window.removeEventListener('resize', this.onResize);
    }
}
</script>

<style scoped>

</style>

import 'core-js/modules/es.number.is-nan';
import 'core-js/modules/es.string.repeat';
import 'core-js/modules/es.array.find';
import 'core-js/modules/es.array.find-index';
import 'core-js/modules/es.math.trunc';
import 'core-js/modules/es.math.sign';
import './dom-remove';
import PluralRules from './plurals';

// Fetch.
if (typeof window.fetch === 'undefined') {
    console.log('Polyfill fetch');

    window.fetch = require('whatwg-fetch').fetch;
}

// Abort Controller, and 'abortable' fetch.
if (typeof window.AbortController === 'undefined') {
    console.log('Polyfill AbortController');
    const { AbortController, abortableFetch } = require('abortcontroller-polyfill/dist/cjs-ponyfill');

    window.fetch = abortableFetch(window.fetch).fetch;
    window.AbortController = AbortController;
}

// Plurals, full replacement.
if (typeof Intl === 'undefined') {
    console.log('Polyfill plurals (full)');

    // @ts-ignore
    window.Intl = { PluralRules };
}
// Plurals, partial replacement.
else if (typeof Intl.PluralRules === 'undefined') {
    console.log('Polyfill plurals (partial)');

    // @ts-ignore
    window.Intl.PluralRules = PluralRules;
}
// Plurals partial rules replacement.
else {
    const test = ['en', 'ja'];
    const supported = Intl.PluralRules.supportedLocalesOf(test);

    if (supported.length < test.length) {
        console.log(`Polyfill plural (rules: ${test.join(', ')})`);

        // @ts-ignore
        window.Intl.PluralRules = PluralRules;
    }
}

// Object entries.
// TODO This doesn't actually run, but somehow without it IE still fails to
// do Object.entries(). Is it because babel can't detect poly-able items
// from within dependencies? But here we kind of 'trigger' it?
if (typeof Object.entries === 'undefined') {
    console.log("Polyfill Object.entries");
    require('core-js/es/object/entries');
}

// URLSearchParams.
if (typeof URLSearchParams === 'undefined') {
    console.log("Polyfill URLSearchParams");
    require('@ungap/url-search-params');
}

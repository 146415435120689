import { PluralRule, PluralSelector } from 'make-plural/types';
import getPluralRules from 'intl-pluralrules/factory';

import { en, ja } from 'make-plural/plurals';

import { en as enCat, ja as jaCat } from 'make-plural/pluralCategories';


const rules: Record<string, PluralSelector> = {
    en,
    ja,
};

const categories: Record<string, PluralRule> = {
    en: enCat,
    ja: jaCat,
};

export default getPluralRules(
    // Not available in IE 10, Safari 9
    Intl.NumberFormat,
    // getSelector
    locale => rules[locale],
    // getCategories
    (locale, type) => categories[locale][type ? 'ordinal' : 'cardinal']
);

<template>
    <Portal selector="#modal-portal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div
                    class="modal-container"
                    :class="[ styleClasses ]"
                    role="dialog"
                >
                    <button
                        class="modal-default-btn"
                        type="button"
                        @click="$emit('close')"
                        aria-label="Close modal"
                    >
                        <span class="icon"></span>
                        <span class="-vis-hidden">Close</span>
                    </button>

                    <div class="modal-body">
                        <slot/>
                    </div>
                </div>
            </div>
        </div>
    </Portal>
</template>

<script>
import { Portal } from '@linusborg/vue-simple-portal';

export default {
    name: 'Modal',
    components: {
        Portal,
    },
    props: {
        classes: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        styleClasses() {
            return this.classes
                .map(value => 'modal--' + value)
                .join(' ');
        },
    }
};
</script>

<style scoped>
/* ---- modal ---- */
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.66);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    position: relative;
    width: calc(100vw - 50px);
    max-width: 800px;
    max-height: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
    margin: 0px 25px;
    background-color: #fff;
    border-radius: 11px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.modal-body {
    overflow-X: hidden;
    overflow-y: auto;
    border-radius: 11px;
    max-height: calc(80vh);
}

@supports (height: 90vh) {
    .modal-container {
        max-height: 88vh;
        margin-top: 0;
        margin-bottom: 0;
    }
}

/* BP Smaller */
@media screen and (min-width: 35em) {
    /* 560px */
    .modal-container {
        width: 92vw;
        margin: 0 auto;
    }
}

/* button */
.modal-default-btn {
    float: right;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    transition: all 200ms ease-in-out;
    background-color: #00b8d6;
    border: solid 2px #00b8d6;
    outline: none;
    z-index: 10;
}

.modal-default-btn .icon {
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    margin-bottom: 0;
}

.modal-default-btn .icon:before,
.modal-default-btn .icon:after {
    content: '';
    position: absolute;
    top: calc(50% - 13px);
    left: 18px;
    width: 2px;
    height: 24px;
    background-color: #fff;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.modal-default-btn .icon:after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.modal-default-btn:hover,
.modal-default-btn:focus,
.modal-default-btn:active {
    background-color: #109AB1;
    border-color: #109AB1;
}

@media only screen and (min-width: 62em) { /* 992px */
    .modal-default-btn {
        top: -20px;
        right: -20px;
    }
}

/* transitions */
.modal-enter,
.modal-leave-active {
    opacity: 0;
}

.modal-enter-active,
.modal-leave-active {
    transition: opacity 200ms ease-in-out;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
    transition: opacity 200ms 100ms ease-in-out;
}

.-vis-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/* ------------------------
Content Specific Overrides
------------------------- */
/* ---- Floor Plan ---- */
.modal-container.modal--floorplan {
    max-width: 300px;
    margin: 0 auto;
}

.modal-container.modal--floorplan >>> img {
    max-height: 75vh;
    width: 100%;
}

@media screen and (min-width: 35em) { /* 560px */
    .modal-container.modal--floorplan {
        max-width: 360px;
    }
}

@media only screen and (min-width: 48em) { /* 768px */
 .modal-container.modal--floorplan {
        max-width: 480px;
    }
}

.modal-container.modal--property-image >>> img {
    width: 100%;
    display: block;
}

</style>

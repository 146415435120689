var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress-bar",class:{
        sticky: _vm.progressBarSticky == true
        },attrs:{"id":"progress-bar"}},[_c('div',{staticClass:"progress-bar__bar"},[_c('div',{staticClass:"wrapper"},[_c('ul',{attrs:{"data-step":_vm.stepCurrent}},_vm._l((_vm.steps),function(step){return _c('li',{key:step.index,staticClass:"step",class:{
                        complete: _vm.stepCurrent > step.index,
                        active: _vm.stepCurrent == step.index,
                        populated: _vm.stepPopulated >= step.index,
                    },attrs:{"data":step.index,"disabled":_vm.stepPopulated >= step.index}},[_c('router-link',{attrs:{"event":_vm.stepPopulated >= step.index ? 'click' : '',"to":step.location}},[_c('span',{staticClass:"icon"},[_c('Icon',{attrs:{"icon":step.icon}})],1),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(step.title)+" ")])])],1)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <header
        class="header"
        :class="{
            'header-reverse--sm': headerReverse == 'sm',
            'header-reverse--md': headerReverse == 'md',
            'header-reverse--md header-reverse--wide': headerReverse == 'md-w',
            'header-reverse--lg': headerReverse == 'lg',
            'confirmation': stepCurrent == 6,
        }"
    >

        <div class="header__inner">

            <HeaderTranslateButtons />

            <div class="header-logo">
                <a
                    v-if="siteHandle == 'kamikita'"
                    href="https://kamikitahouse.com/"
                    class="kamikita-header-image"
                >
                    <img
                        src="@/assets/images/logo_kamikita_black.svg"
                        class="logo-black"
                        alt="Kamikita Logo"
                    />
                    <img
                        src="@/assets/images/logo_kamikita_white.svg"
                        class="logo-white"
                        alt="Kamikita Logo"
                    />
                </a>
                 <a
                    v-else
                    href="https://uninest.jp/"
                >
                    <img
                        src="@/assets/images/logo_black.svg"
                        class="logo-black"
                        alt="UNINEST STUDENT RESIDENCES"
                    />
                    <img
                        src="@/assets/images/logo_white.svg"
                        class="logo-white"
                        alt="UNINEST STUDENT RESIDENCES"
                    />
                </a>
            </div>

        </div>
    </header>
</template>

<script>
// components
import HeaderTranslateButtons from '@/components/HeaderTranslateButtons.vue';

export default {
    name: 'HeaderComp',
    components: {
        HeaderTranslateButtons
    },
    props: {
        headerReverse: {
            type: String,
            required: true
        },
        stepCurrent: {
            type: Number,
        }
    },
    data() {
        return {};
    }
};
</script>

<style scoped>
.header {
    position: relative;
    background-color: #414142;
    color: #fff;
    height: 60px;
    padding-top: 10px;
    padding-right: 20px;
}

@media only screen and (max-width: 47.937em) { /* 767px */
    .header__inner {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        z-index: 98;
        background-color: #414142;
        color: #fff;
        height: 60px;
        padding-top: 10px;
        padding-right: 20px;
    }
}
.header-logo {
    float: right;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    right: 25px;
    visibility: visible;
    opacity: 1;
}
.header-logo a {
    display: block;
    width: 88px;
}
.header-logo .kamikita-header-image {
    width: 40px;
}
.header-logo img {
    position: absolute;
    width: 88px;
}
.kamikita-header-image img {
    width: 40px;
}
.logo-black {
    opacity: 0;
}

@media only screen and (min-width: 48em) {
    /* 768px */
    .header {
        background-color: transparent;
        height: auto;
        padding: 0;
    }
    .header-logo {
        float: none;
        position: fixed;
        z-index: 97;
        top: 20px;
        right: 30px;
        transition: all 400ms ease-in-out;
    }

    .header-logo .kamikita-header-image,
    .kamikita-header-image img {
        width: 88px;
    }


    .logo-black {
        opacity: 1;
    }
    .logo-white {
        opacity: 0;
    }

    .header-reverse--sm .logo-black {
        opacity: 0;
    }
    .header-reverse--sm .logo-white {
        opacity: 1;
    }
    .header-logo:after {
        content: "\00a0";
        position: fixed;
        top: 0;
        height: 100px;
        background: transparent;
        opacity: 0;
        transition: all 400ms ease-in-out;
    }
    .header-reverse--sm .header-logo:after {
        right: 0;
        width: 50vw;
        max-width: 50vw;
        background: rgb(0,0,0);
        background: -moz-linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.45) 100%);
        background: -webkit-linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.45) 100%);
        background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.45) 100%);
    }
    .header-reverse--sm .header-logo img {
        position: absolute;
        z-index: 2;
    }

    .animate_before-enter .header .header-logo:after {
        opacity: 0;
    }

    .animate_enter .header .header-logo:after {
        opacity: 0;
    }

    .animate_after-enter .header .header-logo:after {
        opacity: 1
    }

    .animate_before-leave .header .header-logo:after {
        opacity: 0;
    }

    .animate_leave .header .header-logo:after {
        opacity: 0;
    }

    .animate_after-leave .header .header-logo:after {
        opacity: 0;
    }
}

@media only screen and (min-width: 62em) { /* 992px */
    .header-reverse--md .logo-black {
        opacity: 0;
    }
    .header-reverse--md .logo-white {
        opacity: 1;
    }
    .header-reverse--md .header-logo:after {
        right: 0;
        width: 50vw;
        max-width: 50vw;
        background: rgb(0,0,0);
        background: -moz-linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.45) 100%);
        background: -webkit-linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.45) 100%);
        background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.45) 100%);
    }
    .header-reverse--wide .header-logo:after {
        width: 100vw;
        max-width: 100vw;
    }
    .header-reverse--md .header-logo img {
        position: absolute;
        z-index: 2;
    }
}

@media only screen and (min-width: 80em) { /* 1280px */
    .header-reverse--lg .logo-black {
        opacity: 0;
    }
    .header-reverse--lg .logo-white {
        opacity: 1;
    }
    .header-reverse--lg .header-logo:after {
        right: 0;
        width: 50vw;
        max-width: 50vw;
        background: rgb(0,0,0);
        background: -moz-linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.45) 100%);
        background: -webkit-linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.45) 100%);
        background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.45) 100%);
    }
    .header-reverse--lg .header-logo img {
        position: absolute;
        z-index: 2;
    }
}

</style>

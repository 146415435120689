<template>
    <div
        class="translate-buttons"
        :class="{
            'btn-animate-fadeout': btnAnimate == true,
            'kamikita-translate-btns': siteHandle == 'kamikita',
        }"
    >
        <button
            v-for="code in getOptions()"
            :key="code"
            class="btn btn-outline"
            :class="{ 'active': getLang() === code }"
            @click="setLang(code)"
            :disabled="btnAnimate"
        >
            {{getName(code)}}
        </button>
    </div>
</template>


<script lang="ts">
import Vue from 'vue';
import { loadLang } from '@/api';
import { changeLanguage } from '@/gmaps-install';


const TR_NAMES: Record<string, string> = {
    en: 'EN',
    ja: 'JP',
    zh: 'CN',
}

export default Vue.extend({
    name: 'HeaderTranslateButtons',
     data() {
        return {
            btnAnimate: false,
        }
    },
    methods: {
        getOptions() {
            return window.__config.language_options;
        },
        getLang() {
            return window.__translations.lang;
        },
        async setLang(lang: string) {
            if (lang === this.getLang()) return;

            // Fetch it. This will populate the global __translations object.
            lang = await loadLang(lang);

            await changeLanguage(lang);

            window.__marketing = await this.getMarketing(lang);

            this.$store.commit('disableTransitions', true);
            // Updating this will trigger the whole app to re-render.
            this.$root.$data.lang = lang;

            document.querySelector('html')!.setAttribute('lang', lang);

            await this.$store.dispatch('refresh');

            setTimeout(() => {
                this.$store.commit('disableTransitions', false);
            }, 500);
        },
        async getMarketing(lang: string) {
            const { prefix, site_id } = window.__config;
            const res = await fetch(`/${prefix}/marketing.json?lang=${lang}&site_id=${site_id}`);
            return await res.json();
        },
        getName(code: string) {
            return TR_NAMES[code];
        },
        fadeOutBtns() {
            var wScrollY = window.scrollY

            if (window.innerWidth >= 768) {
                if (wScrollY > 10) {
                    this.btnAnimate = true;
                } else {
                    this.btnAnimate = false;
                }
            } else {
                this.btnAnimate = false;
            }
        }
    },
    created() {
        window.addEventListener('scroll', this.fadeOutBtns);
        window.addEventListener('resize', this.fadeOutBtns);
    },
    mounted() {
        this.fadeOutBtns();
    },
    destroyed() {
        window.removeEventListener('scroll', this.fadeOutBtns);
        window.removeEventListener('resize', this.fadeOutBtns);
    }
});
</script>


<style scoped>
.translate-buttons {
    float: left;
    padding-left: 15px;
    margin-top: 2px;

    box-sizing: border-box;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
}

/* language toggle buttons */
.btn-outline {
    background-color: transparent;
    border-color: #ffff;
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-width: 1px;
    font-size: 1.2rem;

    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
}

.btn-outline + .btn-outline {
    border-left: none;
}

.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active {
    opacity: 0.8;
    background-color: #fff;
    border-color: #fff;
    color: #414142;
}

.btn-outline.active {
    background-color: #fff;
    border-color: #fff;
    color: #414142;
}

@media only screen and (min-width: 48em) {
    .translate-buttons {
        position: fixed;
        top: 20px;
        left: 25px;
        padding-left: 0;
        z-index: 98;
        opacity: 1;
        margin-top: 0;
        transition: opacity 150ms ease-in-out;
    }

    .btn-animate-fadeout {
        opacity: 0;
    }

    /* language toggle buttons */
    .btn-outline {
        background-color: transparent;
        border-color: #414142;
        color: #414142;

        padding-left: 7px;
        padding-right: 7px;
        padding-top: 7px;
        padding-bottom: 6px;
        border-width: 1px;
    }

    .btn-outline:hover,
    .btn-outline:focus,
    .btn-outline:active {
        opacity: 1;
        background-color: #414142;
        border-color: #414142;
        color: #fff;
    }

    .btn-outline.active {
        background-color: #414142;
        border-color: #414142;
        color: #fff;
    }

    /* reverse language toggle buttons */
    .confirmation .btn-outline {
        background-color: transparent;
        border-color: #fff;
        color: #fff;
        opacity: 0.8;
        transition: all 400ms ease-in-out;
    }

    .confirmation .btn-outline:hover,
    .confirmation .btn-outline:focus,
    .confirmation .btn-outline:active {
        opacity: 0.8;
        background-color: #fff;
        border-color: #fff;
        color: #414142;
    }

    .confirmation .btn-outline.active {
        background-color: #fff;
        border-color: #fff;
        color: #414142;
    }
}

@media only screen and (min-width: 62em) { /* 992px */
       /* reverse language toggle buttons */
    .header-reverse--md .btn-outline {
        background-color: transparent;
        border-color: #fff;
        color: #fff;
        opacity: 0.8;
    }

    .header-reverse--md .btn-outline:hover,
    .header-reverse--md .btn-outline:focus,
    .header-reverse--md .btn-outline:active {
        opacity: 0.8;
        background-color: #fff;
        border-color: #fff;
        color: #414142;
    }

    .header-reverse--md .btn-outline.active {
        background-color: #fff;
        border-color: #fff;
        color: #414142;
    }
}
</style>

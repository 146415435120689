
// The original API loader is here:
// https://github.com/xkjyeah/vue-google-maps/blob/vue2/src/manager/initializer.js
// This didn't provide any mechanism to change the maps language.
// So here we are!

import Vue from 'vue';
import type { VueConstructor } from 'vue';

interface Options {
    key: string;
    libraries: string[];
    language: string;
}

interface VueGmap extends Vue {
    $gmapDefaultResizeBus: Vue;
    $gmapApiPromiseLazy: () => Promise<void>;
    $gmapOptions: {
        autoBindAllEvents: boolean;
        installComponents: boolean;
    }
}

declare global {
    interface Window {
        __vueGoogleMapsInit: () => void;
    }
}

const BASE_URL = "https://maps.googleapis.com";

// State management.
const GmapApi = new Vue({data: {
    loaded: null as object | null,
    options: null as Options | null,
}});

export function install(Vue: VueConstructor, options: Options) {

    const defaultResizeBus = new Vue();

    const gmapApiPromiseLazy = () => loadGmapApi(options);

    // These properties are used by the Gmap-Vue components.
    Vue.mixin({
        created(this: VueGmap) {
            this.$gmapDefaultResizeBus = defaultResizeBus;
            this.$gmapApiPromiseLazy = gmapApiPromiseLazy;
            this.$gmapOptions = {
                autoBindAllEvents: false,
                installComponents: false,
            };
        }
    })

    // @ts-ignore
    Vue.$gmapDefaultResizeBus = defaultResizeBus;
    // @ts-ignore
    Vue.$gmapApiPromiseLazy = gmapApiPromiseLazy;
}

export async function loadGmapApi(options: Options): Promise<void> {
    // No loading in SSR.
    if (typeof document === 'undefined') return;

    // Don't double load.
    if (GmapApi.loaded) {
        window.__vueGoogleMapsInit();
        return;
    }

    const url = BASE_URL + "/maps/api/js?" + stringify({
        ...options,
        libraries: options.libraries.join(','),
        callback: '__vueGoogleMapsInit',
    });

    // Load the google maps api.
    const script = document.createElement('script');
    script.setAttribute('src', url);
    script.setAttribute('async', '');
    script.setAttribute('defer', '');
    document.head.appendChild(script);

    // Google's script loader will call our global function when it's ready.
    // In turn, we'll resolve our promise to let caller's also know.
    return new Promise<void>(resolve => {
        window.__vueGoogleMapsInit = function() {
            resolve();
            GmapApi.options = options;
            GmapApi.loaded = {};
        };
    });
}

export async function changeLanguage(language: string): Promise<string> {

    // Not initialised, not ready.
    if (!GmapApi.loaded || !GmapApi.options) return language;

    GmapApi.loaded = null;
    // @ts-ignore
    window.google.maps = null;

    // Delete the existing google maps scripts.
    const scripts = document.querySelectorAll(`script[src^='${BASE_URL}'`);
    scripts.forEach(script => script.remove());

    // Load fresh ones.
    await loadGmapApi({
        ...GmapApi.options,
        language,
    });

    return language;
}

// Mask api access behind our vue state component.
// This provides proxy and hot-loading support. I assume.
export function gmapApi() {
    return GmapApi.loaded && window.google;
}

// Helper function.
// TODO Maybe put this somewhere else.
function stringify(params: Record<string, any>): string {
    return Object.keys(params)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
        .join('&');
}

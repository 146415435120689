<template>
    <footer id="footer" class="footer">
        <div class="row">
            <div class="col-xs-12 col-xsm-7 col-sm-6 footer__logos">
                <a
                    v-if="siteHandle == 'uninest'"
                    class="logo-gsa"
                    href="https://www.gsa-gp.com/ja"
                    target="_blank"
                >
                    <img
                        src="@/assets/images/logo_gsa.svg"
                        alt="GSA SPACE TO GROW"
                    />
                </a>

                <a
                    v-if="siteHandle == 'uninest'"
                    class="logo-uninest"
                    href="https://unineststudents.com"
                    target="_blank"
                >
                    <img
                        src="@/assets/images/logo_footer.svg"
                        alt="UNINEST STUDENT RESIDENCES"
                    />
                </a>

                <img
                    class="footer__logo--kamikita"
                    v-if="siteHandle == 'kamikita'"
                    src="@/assets/images/logo_kamikita_white.svg"
                    alt="Kamikita"
                />
            </div>

            <div class="col-xs-0 col-md-3 footer__empty"></div>

            <div
                class="col-xs-12 col-xsm-5 col-sm-6 col-md-3 col-lg footer__social row bottom-xs end-xsm"
            >
                <ul class="social">
                    <li class="item">
                        <a
                            :href="facebookLink"
                            target="_blank"
                            v-if="siteHandle != 'kamikita'"
                        >
                            <img
                                src="@/assets/images/ico_facebook.svg"
                                alt="facebook"
                        /></a>
                    </li>
                    <li class="item">
                        <a
                            :href="instagramLink"
                            target="_blank"
                        >
                            <img
                                src="@/assets/images/ico_instagram.svg"
                                alt="instagram"
                        /></a>
                    </li>
                    <li class="item">
                        <a
                            :href="twitterLink"
                            target="_blank"
                            v-if="siteHandle != 'kamikita'"
                        >
                            <img
                                src="@/assets/images/ico_twitter.svg"
                                alt="twitter"
                        /></a>
                    </li>
                </ul>
            </div>
        </div>
        <p class="copyright">Copyright &copy;
            <span
            v-if="siteHandle == 'uninest'">
                GSA
            </span>
            <span
            v-if="siteHandle == 'kamikita'">
                Uninest GK
            </span>
            All rights reserved</p>
    </footer>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    name: 'FooterComp',
    computed: {
        facebookLink() {
            if (this.siteHandle == 'kamikita') {
                return 'kamikita-social-facebook';
            }
            return 'https://www.facebook.com/Hakusan-House-Uninest-Student-Residences-Tokyo-229987227528203/';
        },
        instagramLink() {
            if (this.siteHandle == 'kamikita') {
                return 'https://www.instagram.com/kamikitahouse/';
            }
            return 'https://www.instagram.com/uninestjapan/?hl=ja';
        },
        twitterLink() {
            if (this.siteHandle == 'kamikita') {
                return 'kamikita-social-twitter';
            }
            return 'https://twitter.com/uninestjapan';
        }
    }
});
</script>

<style scoped>
.footer {
    background-color: #414142;
    color: #fff;
}
.footer > .row {
    padding-top: 35px;
    padding-bottom: 40px;
}
.footer__logos,
.footer__social {
    padding-right: 20px;
    padding-left: 20px;
}
.footer__logos a {
    display: inline-block;
}
.footer__logos img {
    margin-right: 45px;
}
.footer__logo--kamikita {
    width: 88px;
    height: 86px;
}
.social {
    list-style-type: none;
    padding-top: 10px;
}
.social .item {
    display: inline-block;
    padding: 0 6px;
    vertical-align: top;
}
.social a {
    display: block;
}

.copyright {
    text-align: center;
    font-size: 9px;
    font-weight: 400;
    margin: 0;
    padding: 0 0 18px;
    text-transform: uppercase;
}

/* BP Smaller */
@media screen and (min-width: 35em) {
    /* 560px */
    .social {
        padding-top: 0;
    }
}

@media only screen and (min-width: 48em) {
    /* 768px */
    .footer > .row {
        padding-top: 0;
        padding-bottom: 0;
    }
    .footer__logos,
    .footer__empty,
    .footer__social {
        padding-top: 35px;
        padding-bottom: 40px;
    }
    .footer__logos {
        background-color: #4b4b4b;
    }
    .footer__social {
        background-color: #555555;
    }
    .copyright {
        padding-top: 18px;
    }
}

@media only screen and (min-width: 62em) {
    /* 992px */
    .footer__empty {
        background-color: #555555;
        max-width: 320px;
    }
    .footer__social {
        background-color: #646763;
    }
}

@media only screen and (min-width: 80em) {
    /* 1280px */
    .footer__logos {
        padding-left: calc((100vw - 1010px) / 2);
    }
    .footer__social {
        padding-right: calc((100vw - 1010px) / 2);
    }
}
</style>

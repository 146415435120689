
import Vue from 'vue';

export default Vue.extend({
    props: {
        icon: {
            type: String,
            required: true,
        },
    },
    render(h) {
        return h(require(`@/assets/icons/${this.$props.icon}.svg`));
    },
});

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header",class:{
        'header-reverse--sm': _vm.headerReverse == 'sm',
        'header-reverse--md': _vm.headerReverse == 'md',
        'header-reverse--md header-reverse--wide': _vm.headerReverse == 'md-w',
        'header-reverse--lg': _vm.headerReverse == 'lg',
        'confirmation': _vm.stepCurrent == 6,
    }},[_c('div',{staticClass:"header__inner"},[_c('HeaderTranslateButtons'),_c('div',{staticClass:"header-logo"},[(_vm.siteHandle == 'kamikita')?_c('a',{staticClass:"kamikita-header-image",attrs:{"href":"https://kamikitahouse.com/"}},[_c('img',{staticClass:"logo-black",attrs:{"src":require("@/assets/images/logo_kamikita_black.svg"),"alt":"Kamikita Logo"}}),_c('img',{staticClass:"logo-white",attrs:{"src":require("@/assets/images/logo_kamikita_white.svg"),"alt":"Kamikita Logo"}})]):_c('a',{attrs:{"href":"https://uninest.jp/"}},[_c('img',{staticClass:"logo-black",attrs:{"src":require("@/assets/images/logo_black.svg"),"alt":"UNINEST STUDENT RESIDENCES"}}),_c('img',{staticClass:"logo-white",attrs:{"src":require("@/assets/images/logo_white.svg"),"alt":"UNINEST STUDENT RESIDENCES"}})])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }